<template>
  <div class="max-w-3xl mt-3 flex flex-col gap-2">
    <div class="flex gap-2">
      <b-btn
        variant="primary"
        size="sm"
        @click="createRole()"
        :disabled="!canCreateRole"
      >
        Create role
      </b-btn>
    </div>

    <div>
      <table class="table table-google table-highlight">
        <thead>
          <tr>
            <th class="text-nowrap row-fit">ID</th>
            <th class="text-nowrap">Keyword</th>
            <th class="text-nowrap">Description</th>
            <th class="text-nowrap">Category</th>
            <th class="text-nowrap row-fit">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colspan="3">
              <div class="font-bold">
                <span v-if="loadingCount > 0">
                  Loading..
                </span>
              </div>
            </td>
          </tr>

          <tr v-for="role in roles" :key="`role_${role.id}`">
            <td>{{ role.id }}</td>
            <td>
              <b-form-input
                v-if="role.edit"
                v-model="role.keyword"
                autocomplete="off"
                trim
                size="sm"
              ></b-form-input>
              <span v-else>
                {{ role.keyword }}
              </span>
            </td>
            <td>
              <b-form-input
                v-if="role.edit"
                v-model="role.description"
                autocomplete="off"
                trim
                size="sm"
              ></b-form-input>
              <span v-else>
                {{ role.description }}
              </span>
            </td>
            <td>
              <b-form-input
                v-if="role.edit"
                v-model="role.category"
                autocomplete="off"
                trim
                size="sm"
              ></b-form-input>
              <span v-else>
                {{ role.category }}
              </span>
            </td>
            <td>
              <div v-if="role.edit" class="flex gap-2">
                <b-btn
                  variant="primary"
                  size="sm"
                  @click="saveRole(role)"
                  :disabled="role.busy"
                  class="w-16"
                >
                  Save
                </b-btn>
                <b-btn
                  variant="white"
                  size="sm"
                  @click="cancelRole(role)"
                  :disabled="role.busy"
                  class="w-16"
                >
                  Cancel
                </b-btn>
              </div>
              <div v-else class="flex gap-2">
                <b-btn
                  variant="primary"
                  size="sm"
                  @click="editRole(role)"
                  :disabled="role.busy"
                  class="w-16"
                >
                  Edit
                </b-btn>
                <b-btn
                  variant="danger"
                  size="sm"
                  @click="deleteRole(role)"
                  :disabled="role.busy"
                  class="w-16"
                >
                  Delete
                </b-btn>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Roles',
  components: {},
  computed: {
    canCreateRole() {
      return this.roles.filter((role) => role.id === null).length === 0;
    },
  },
  data() {
    return {
      roles: [],
    };
  },
  methods: {
    createRole() {
      this.roles.unshift({
        id: null,
        keyword: '',
        description: '',
        category: '',
        edit: true,
        busy: false,
      });
    },
    fetchRole(role) {
      role.busy = true;
      this.$http
        .get(`/roles/${role.id}`)
        .then((res) => {
          Object.keys(res.body).forEach((key) => {
            role[key] = res.body[key];
          });
        })
        .catch((err) => {
          this.$toast.error(`Failed to fetch role: ${err.response.text}`);
        })
        .finally(() => {
          role.busy = false;
        });
    },
    fetchRoles() {
      this.loadingCount++;
      this.$http
        .get('/roles')
        .then((res) => {
          this.roles = res.body.map((role) => {
            role.edit = false;
            role.busy = false;
            return role;
          });
        })
        .catch((err) => {
          this.$toast.error(`Failed to fetch roles: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    saveRole(saveRole) {
      saveRole.busy = true;
      this.$http
        .post('/roles')
        .send({ role: saveRole })
        .then((res) => {
          Object.keys(res.body).forEach((key) => {
            saveRole[key] = res.body[key];
          });
          saveRole.edit = false;
        })
        .catch((err) => {
          this.$toast.error(`Failed to send role: ${err.response.text}`);
        })
        .finally(() => {
          saveRole.busy = false;
        });
    },
    cancelRole(cancelRole) {
      cancelRole.edit = false;
      if (cancelRole.id === null) {
        this.roles = this.roles.filter((role) => role.id !== null);
      } else {
        this.fetchRole(cancelRole);
      }
    },
    editRole(editRole) {
      editRole.edit = true;
    },
    deleteRole(deleteRole) {
      if (!confirm(`Do you really wish to delete '${deleteRole.description}'?`)) {
        return;
      }
      deleteRole.busy = true;
      this.$http
        .delete(`/roles/${deleteRole.id}`)
        .then(() => {
          this.roles = this.roles.filter((role) => role.id !== deleteRole.id);
        })
        .catch((err) => {
          this.$toast.error(`Failed to delete role: ${err.response.text}`);
        })
        .finally(() => {
          deleteRole.busy = false;
        });
    },
  },
  created() {
    this.fetchRoles();
  },
};
</script>

<style lang="scss" scoped>
</style>
